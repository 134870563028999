import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Vorbereiten einer Stellenausschreibung auf dem Portal",
  "description": "So bearbeiten Sie eine Ausschreibung im Portal",
  "author": "Jochen Ritscher",
  "categories": ["gettingstarted"],
  "date": "2021-08-13T00:00:00.000Z",
  "featured": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Eine Ausschreibung können Sie im Portal Analog zu den Feldern in Business Central im Menüpunkt "Jobs" erstellen.`}</p>
    <h2>{`Wie füge ich Daten in Auswahlfeldern ein, die noch fehlen?`}</h2>
    <p>{`Neben der Feldüberschrift von Auswahlfeldern wird ein "Hinzufügen"-Button eingeblendet. Klicken sie auf das "+", öffnet sich ein neues Fenster an dem Sie die benötigte Auswahl hinterlegen können. Darauf können Sie zur Stellenanzeige zurückkehren, die Seite neu laden und den neuen Wert auswählen.`}</p>
    <img src="/images/gettingstarted/ausschreibung01.jpg" alt="ausschreibungskarte" style={{
      "width": "100%"
    }} />
    <h2>{`Wo pflege ich Stand- und Einsatzorte?`}</h2>
    <p>{`Einrichtung / Einstellungen / Niederlassungen`}</p>
    <h2>{`Wo pflege ich Branchen?`}</h2>
    <p>{`Einrichtung / Einstellungen / Branchen`}</p>
    <h2>{`Wo gebe ich fehlende Ansprechpartner/Disponenten ein?`}</h2>
    <p>{`Einrichtung / Einstellungen / Disponenten`}</p>
    <h2>{`Kann ich Konditionen hinterlegen wie z. Bsp. Starttermin / Projektbeginn und Vergütung, Projektdauer?`}</h2>
    <p>{`In den Beschreibungs-Textfeldern wie dem Kopftext können Sie alle relevanten Rahmeninformationen für die Stelle hinterlegen.`}</p>
    <h2>{`Habe ich die Möglichkeit neben dem eigentlichen Jobtitel und dem alternativen SEO Titel auch eine interne, nur für das Backend ersichtliche Bezeichnung zu hinterlegen (z.B. Ingenieur/in | Branche XY, dahinter „versteckt“ sich aber Kunde X (Ort) | Branche)`}</h2>
    <p>{`Dafür kann ein internes Bezeichnungsfeld  eingeblendet werden. Außerdem können Sie den Debitor auch in ein dafür vorgesehenes Debitoren-Feld eintragen so dass Sie später leichter danach sortieren können. Bitte kontaktieren Sie unseren Support wenn Sie diese Funktion nutzen möchten.`}</p>
    <h2>{`Position in Liste`}</h2>
    <p>{`Mit dem Feld "Position in Liste" können sie die Reihenfolge der Stellenanzeige im Karriereportal bestimmen , wenn diese von der Standard-Sortierung (Neueste Ausschreibung an erster Stelle) abweichen soll. Die Position kann zwischen 1 (ganz oben) und der Anzahl aktiver Stellen (ganz unten) variiert werden.Bitte beachten Sie, dass die Reihenfolge übergreifend für alle Listen/Widgets festgelegt wird. `}</p>
    <img src="/images/gettingstarted/ausschreibung02.png" alt="Position in Liste" style={{
      "width": "100%"
    }} />
    <p>{`Um die Zuordnung von Positionen zu kontrollieren können Sie in der Ausschreibungsliste in der Verwaltungsansicht einfach nach der Positionsnummer sortieren:`}</p>
    <h2>{`Stellenanzeige an die erste Stelle setzen (Refresh)`}</h2>
    <p>{`Mit der Funktion "Nach oben setzen" können Sie in der Bearbeitungsansicht eine Stellenanzeige wieder an die erste Position in der Jobliste setzen.`}</p>
    <img src="/images/gettingstarted/ausschreibung03.png" alt="Stellenanzeige an die erste Stelle setzen" style={{
      "width": "100%"
    }} />
    <p>{`Bitte beachten Sie dass ggf. individuelle Einstellungen Ihres Widgets dieses Feature beeinflussen können.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      